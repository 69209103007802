<template>
  <div class="setting_form">
    <b-modal modal-class="medium-width-modal modal_basic  " id="addServiceLists" hide-footer hide-header>
      <img @click="$bvModal.hide('addServiceLists')" class="close_modal" src="/assets/img/cross.svg" alt="">


      <div class="basic_form modal_content">


        <div class="heading">
          <h3>
            Select your account details
          </h3>
        </div>


        <div class="basic_form">
          <template v-if="service_lists">


            <template v-if="service_name == 'aweber'">
              <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
                <div class="content">
                  <div class="url">Enter account name</div>
                  <div class="name">
                    <div class="validation_input">
                      <input type="text" v-model="name" placeholder="Enter account name">
                    </div>
                  </div>
                </div>
              </div>
            </template>


            <div class=" " v-if="service_lists.length > 0">
              <label>Select your lists which you want to integrate with {{ getSiteDetails.agency_name }}</label>
              <ul class="check_list service_lists_checkbox ">


                <p style="width:100%" class="checkbox_input_image  " v-for="list in service_lists" :key="list.id">
                  <input v-model="selected_lists" type="checkbox" :value="list" :id="list.id">
                  <label :for="list.id" class="checkbox_left">{{ list.name }}</label>
                </p>
              </ul>
            </div>


          </template>
          <template v-else>
            <p class="sub_heading_inside">
              You do not have any lists in your account, please create list in your account first.
            </p>
          </template>
        </div>


        <div class=" btn_block text-left">


          <button @click.prevent="saveLists()" class="btn---cta btn-blue with-shadow btn-round  btn-bold">
            <span>Save</span>
          </button>
          <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                  @click="$bvModal.hide('addServiceLists')"><span>Close</span>
          </button>
        </div>


      </div>
    </b-modal>
    <div class="white_box integration-block  ">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Integrations</h2>
          </div>
          <div class="right ml-auto">
            <div class="toggle_btn_link" style="border: 2px solid rgba(179, 179, 195, 0.17);">
              <p class="total_configured_integrations">{{ integrations.filter(item => item.length > 0).length }}</p>
              <div class="toggle_btn_inner">
                <span :class="{'active_tab' : toggleIntegrationTabState === 'configured'}"
                      @click="toggleIntegrationTabState = 'configured'" class="btn_link ">
                                    Available Integrations
                                </span>
                <span :class="{'active_tab' : toggleIntegrationTabState === 'available'}"
                      @click="toggleIntegrationTabState = 'available'" class="btn_link">
                                    Configured  Integrations
                                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="box_content clear">
          <div v-if="toggleIntegrationTabState === 'configured'" id="available_integrations"
               class="tab-content">
            <div class="p-5 text-center" v-if="loader">
              <clip-loader :color="color" :size="size"></clip-loader>
            </div>
            <div class="integration_box" v-else>

              <div class="inner" :key="index" v-if="integrations && integrations.length > 0 && item.key!='drip'"
                   v-for="(item,index) in integrations">
                <div class="icon_sec integration_icon">
                  <img :src="item.logo" alt="">
                </div>
                <div class="content_sec">
                  <h3>{{ item.name }}</h3>
                  <p>{{ item.description }}</p>
                </div>
                <div class="btn_sec">
                  <p class="total_integrations" v-b-tooltip="item.length + ' account(s) connected'"
                     v-if="item.length > 0">{{ item.length }}</p>
                  <a
                    v-if="item.custom && item.meta==null"
                    class="btn---cta btn-blue with-shadow btn-round   btn-bold"
                    :href="item.routeName"><span>Connect</span></a>
                  <router-link v-else-if="item.custom && item.meta!=null"
                               :to="{'name': item.routeName}"
                               class="btn---cta btn-blue with-shadow btn-round   btn-bold">
                    <span>Connect</span>
                  </router-link>
                  <router-link v-else
                               :to="{'name': item.routeName}"
                               class="btn---cta btn-blue with-shadow btn-round   btn-bold">
                    <span>Connect</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="toggleIntegrationTabState === 'available'" id="configured_integrations"
               class="tab-content">
            <div class="p-5 text-center" v-if="loader">
              <clip-loader :color="color" :size="size"></clip-loader>
            </div>
            <div v-else-if="integrations && integrations.filter(item => item.length > 0).length > 0">
              <template v-if="item && item.length > 0 && !loader" v-for="(item,index) in integrations">
                <div class=" integration_config"
                     :class="{'btn--effect-pulse':item.expire}">
                  <div class="box_inner">

                    <div class="content_sec">
                      <div class="head d-flex align-items-start">
                        <div class="content">
                          <div class="i_img">
                            <img :src="item.logo" alt="">
                          </div>
                          <p class="p-name">{{ item.name }}</p>
                          <p class="list_name_head">Account name</p>
                          <p class="list_name">{{ item.accountName }}</p>
                        </div>
                        <span class="status">Connected</span>
                      </div>
                    </div>
                    <div class="btn_sec">
                      <button class="btn---cta btn-blue with-shadow btn-round   btn-bold"
                              @click.prevent="reconnect(item)">
                        <span>Reconnect</span>
                      </button>
                      <button class="btn---cta   btn---transparent color---red  btn-bold"
                              @click.prevent="remove(item)">
                        <span>Disconnect</span>
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <p v-else class="no_integration">You have not integrated any third-party application yet.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  getAuthorizationURL,
  removeIntegrationURL,
  reconnectIntegrationURL,
  fetchIntegrationListURL,
  getIntegrationMessageURL
} from '@/config/config.js'
import GuidedTour from '@/views/guided-tour/GuidedTour.vue'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'
import http from '@/mixins/http-lib'
import vueCookie from 'vue-cookie'
import { AWEBER_KEY, cookieAppDomain, INTEGRATION_COOKIE } from '../../../constants'
import {notAllowedIntegrationsInWhitelabel, hiddenIntegrationList} from "@/common/attributes";

export default ({
  components: {
    GuidedTour
  },
  data () {
    return {
      authorizationURL: {
        aweber: null
      },
      service_name: null,
      service_lists: [],
      selected_lists: [],
      account_id: null,
      loader: true,
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      name: '',
      toggleIntegrationTabState: 'configured',

      integrations: [],
      integrationList: [],
      notAllowedIntegrationsInWhitelabel,
      hiddenIntegrationList
    }
  },
  async created () {
    await this.getAuthorization()
    await this.fetchIntegrationList()
    if (this.$route.query && this.$route.query.message) {
      this.alertMessage(this.$route.query.message)
    }
    this.getIntegrationMessage()
  },
  computed: {
    ...mapGetters([
      'getGuidedTour',
      'getProfile'
    ])

  },
  methods: {
    ...mapActions([
      'setLinksFetchStatus',
      'fetchProfile'
    ]),

    async getAuthorization () {
      return http.post(getAuthorizationURL).then(
        response => {
          if (response.data.status) {
            this.authorizationURL = response.data.authorization
            //todo: Remove this as we are not setting the aweber_secret when comment
            // vueCookie.set(AWEBER_KEY, response.data.authorization.aweber_secret, {
            //   expires: '1D',
            //   domain: cookieAppDomain
            // })
          }
          return false
        },
        err => {
          this.alertMessage(err.message, 'error')
        }
      )
    },

    remove (item) {
      let self = this
      swal({
          title: 'Are you sure ?',
          type: 'warning',
          text: 'Your associated link campaigns for this account will be paused.',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Delete it!',
          cancelButtonText: 'No, Cancel!',
          closeOnConfirm: true,
          customClass: 'sweetAlert_box',
          closeOnCancel: true,
          animation: false
        },
        function (isConfirm) {
          if (isConfirm) {
            self.loader = true
            http.post(removeIntegrationURL, {
              'type': item.key,
              'account_id': item.accountId,
              'integration_id': item._id
            }).then(
              async response => {
                if (response.data.status) {
                  self.alertMessage(response.data.message, 'success')
                  await self.getAuthorization()
                  await self.fetchProfile()
                  await self.fetchIntegrationList()
                }
              },
              err => {
                this.alertMessage(err.message, 'error')
              }
            )
          }
        })
    },

    /**
     * Method sets the cookie for integration settings and redirects to the desired integration url
     */
    reconnect (item) {
      let self = this
      swal({
          title: 'Are you sure ?',
          type: 'warning',
          text: 'If you reconnect with some different account, your added link campaigns will be paused.',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, I Understand!',
          cancelButtonText: 'No, Cancel!',
          closeOnConfirm: true,
          customClass: 'sweetAlert_box',
          closeOnCancel: true,
          animation: false
        },
        function (isConfirm) {
          if (!isConfirm) return
          if (!item.accountId) {
            document.location.href = url
            return
          }
          /**
           * if saveCookie exists, save the integration config in cookie,
           * otherwise use the conventional way of saving it in server session.
           * TODO: We need to make sure we update every integration to cater this via cookie
           * instead of an extra call for saving session.
           * Once all integrations are upgraded, this block of condition will be removed.
           *
           * Integrated:
           * BenchmarkEmail, ConstantContact, Gist, Mautic, Sendinblue, SendLane
           * GetResponse, FreshMail, ConvertKit, Moosend, MailChimp, MailerLite
           * SendPulse
           *
           */
          if (!!item.saveCookie) {
            const integration = {
              'account_id': item.accountId,
              'type': item.key,
              'expire': item.expire,
              'integration_id': item._id
            }
            vueCookie.set(INTEGRATION_COOKIE, JSON.stringify(integration))
            if (item.custom && !item.meta) {
              document.location.href = item.routeName
              return true
            }

            self.$router.push({
              name: item.routeName
            })
            return
          }

          http.post(reconnectIntegrationURL, {
            'account_id': item.accountId,
            'type': item.key,
            'expire': item.expire,
            'integration_id': item._id
          }).then(
            response => {
              if (response.data.status) {
                if (item.custom && !item.meta) {
                  document.location.href = item.routeName, true
                } else {
                  self.$router.push({
                    name: item.routeName
                  })
                }
              }
            },
            err => {
              this.alertMessage(err.message, 'error')
            }
          )
        })
    },

    /**
     * Based on the integration_id in the url, we send the request to backend to fetch the lists of that specific integration.
     * If lists are not available, we issue the desired message
     */
    getIntegrationMessage () {
      this.name = ''
      http.post(getIntegrationMessageURL, { integration_id: this.$route.query.integration_id }).then(
        response => {
          if (response.data.status) {
            this.alertMessage(response.data.message, 'success')
            if (response.data.type) {
              if (response.data.type === 'email') {
                try {
                  this.service_lists = response.data.lists
                  this.service_name = response.data.service
                  this.account_id = response.data.account_id
                  this.$bvModal.show('addServiceLists')
                } catch (m) {
                  this.alertMessage(m.message, 'error')
                }
              }
            }
            return
          }

          if (response.data.status === false && response.data.message) {
            this.alertMessage(response.data.message, 'error')
          }
        },
        err => {
          this.alertMessage(err.message, 'error')
        }
      )
    },

    /**
     * After successful connection with an integration, we select amongst the lists fetched from the integration.
     * selected lists are saved in the application
     */
    saveLists () {
      let thiss = this
      if (this.selected_lists.length === 0) {
        this.alertMessage('Please select atleast one list.', 'error')
        return
      }
      if (this.service_name == 'aweber' && (!this.name || (this.name.trim()).length == 0)) {
        this.alertMessage('Please enter project name', 'error')
        return
      }
      let post_data = {
        lists: this.selected_lists,
        id: this.account_id,
        integration_id: this.$route.query.integration_id,
      }
      let postURL = null
      const selectedIntegration = this.integrationList.find(integration => integration.key === this.service_name)
      /**
       * In case of aweber, we have to submit the project name. Therefore the dynamic array of integration contains a key
       * called 'allowName' only if that specific integration requires name in its payload
       */
      if ('allowName' in selectedIntegration) {
        post_data['name'] = this.name
      }
      postURL = selectedIntegration.saveListURL
      http.post(postURL, post_data).then(
        response => {
          if (response.data.status) {
            this.alertMessage(response.data.message, 'success')
            this.getUserProfile()
            thiss.$bvModal.hide('addServiceLists')
            return
          }
          this.alertMessage(response.data.message, 'error')
        },
        err => {
          this.alertMessage(err.message, 'error')
        }
      )
    },

    /**
     * Method is responsible to fetch a list of integrations. All meta data related to the integrations to display it in integrations section
     */
    async fetchIntegrationList () {
      return http.post(fetchIntegrationListURL)
        .then(
          res => {
            if (res.data.status) {
              this.integrationList = res.data.data
              this.integrations = this.integrationList.map(item => {
                return {
                  key: item.key,
                  whitelabel: !this.notAllowedIntegrationsInWhitelabel.includes(item.key),
                  isHide: this.hiddenIntegrationList.includes(item.key),
                  logo: item.logo,
                  name: item.value,
                  custom: item.custom,
                  meta: item.meta,
                  saveCookie: !!item.saveCookie,
                  description: `Send subscribers directly to ${item.value} from ${this.getSiteDetails.agency_name}`,
                  length: this.getProfile[item.key] ? (this.getProfile[item.key].length || 0) : 0,
                  routeName: this.getRouteName(item.key),
                  expire: this.getProfile[item.key] ? (this.getProfile[item.key] && this.getProfile[item.key][0] ? (this.getProfile[item.key][0].expire || '') : '') : '',
                  accountName: this.getProfile[item.key] ? (this.getProfile[item.key] && this.getProfile[item.key][0] ? (this.getProfile[item.key][0].name || '') : '') : '',
                  accountId: this.getProfile[item.key] ? (this.getProfile[item.key] && this.getProfile[item.key][0] ? (this.getProfile[item.key][0].account_id || '') : '') : '',
                  _id: this.getProfile[item.key] ? (this.getProfile[item.key] && this.getProfile[item.key][0] ? (this.getProfile[item.key][0]._id || '') : '') : '',
                }
              })
              this.integrations = this.getSiteDetails.isWhiteLabel ?  this.integrations.filter(item => item.whitelabel) : this.integrations
              this.integrations = this.integrations.filter(item => !item.isHide)
            }
            this.loader = false
            return true
          },
          err => {
            this.alertMessage(err.message, 'error')
            this.integrationList = []
            this.integrations = []
            this.loader = false
          }
        )
    },
    getRouteName (key) {
      const integration = this.integrationList.find(integration => integration.key == key)
      return integration.custom ? (integration.meta ? integration.meta : this.authorizationURL[integration.key]) : key
    }
  }

})
</script>

<style scoped lang="less">
.integration_config {
  .color---red {
    span {
      &:hover {
        border-bottom: 1px solid #f76a60;
      }
    }
  }
}

.total_integrations {
  position: absolute;
  font-size: 12px;
  background-color: red;
  width: 20px;
  text-align: center;
  border: 1px solid red;
  border-radius: 25px;
  margin-left: 130px;
  margin-top: -17px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.total_configured_integrations {
  position: absolute;
  font-size: 12px;
  background-color: red;
  width: 20px;
  text-align: center;
  border: 1px solid red;
  border-radius: 25px;
  margin-left: 290px;
  margin-top: -17px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
</style>
